import React, { useState } from 'react';

import Layout from '../../components/layout';
import Seo from '../../components/seo';
import TabNav from '../../components/TabNav';
import WithSidebar from '../../components/WithSidebar';

import { theme } from '../../styles/theme';

const tabs = [
  {
    text: 'RBC',
    colour: 'red',
  },
  {
    text: 'FFP/CRYO',
    colour: 'yellow',
  },
  {
    text: 'PLT',
    colour: 'purple',
  },
];

const AdultsPage = () => {
  const [currentView, setCurrentView] = useState('RBC');

  return (
    <Layout navTitle="Infants &amp; Children">
      <Seo title="Blood Components - Infants &amp; Children" />
      <WithSidebar>
        <TabNav
          label="Component choice"
          tabs={tabs}
          active={currentView}
          onClick={text => setCurrentView(text)}
        />

        <div>
          {currentView === 'RBC' && (
            <article
              css={theme.components.rowSmall}
              className="theme"
              style={{ '--theme': theme.colours.red }}>
              <h1>Red cells</h1>
              <h2>Acute paediatrics</h2>
              <p>Studies support restrictive transfusion thresholds</p>

              <ul>
                <li>
                  <p>
                    Use Hb threshold of 70 g/L in stable non-cyanotic patients.
                  </p>
                </li>

                <li>
                  <p>
                    In non-bleeding infants and children, generally aim for a
                    post-transfusion Hb of no more than 20 g/L above the
                    threshold.
                  </p>
                </li>

                <li>
                  <p>
                    Minimise blood sampling and use near patient testing where
                    possible.
                  </p>
                </li>
              </ul>

              <h2>Surgery (non-cardiac)</h2>
              <ul>
                <li>
                  <p>
                    Use a peri-op Hb threshold of 70 g/L in stable patients
                    without major comorbidity or bleeding.
                  </p>
                </li>
                <li>
                  <p>
                    Use a peri-op Hb threshold of 70 g/L in stable patients
                    without major comorbidity or bleeding.
                  </p>
                </li>
                <li>
                  <p>
                    Consider tranexamic acid in all children undergoing surgery
                    at risk of significant bleeding.
                  </p>
                </li>
                <li>
                  <p>
                    Consider cell salvage in all children at risk of significant
                    bleeding where transfusion may be required.
                  </p>
                </li>
              </ul>

              <h2>Transfusion volume calculation and prescribing</h2>
              <p
                css={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                  textAlign: 'center',
                  paddingTop: '1rem',
                }}>
                <span css={{ paddingRight: theme.gap }}>
                  Volume to transfuse (mL) &nbsp;=
                </span>
                <span>
                  <span
                    css={{
                      display: 'block',
                      borderBottom: '1px solid currentColor',
                    }}>
                    [desired Hb (g/L) – actual Hb (g/L)] &times; weight (kg)
                    &times; 4
                  </span>
                  <span>10</span>
                </span>
              </p>

              <p>
                The formula provides a guide to the likely rise in Hb following
                transfusion for non-bleeding patients.
              </p>

              <ul className="pt-gap">
                <li>
                  <p>Prescription should be in millilitres not units.</p>
                </li>
                <li>
                  <p>
                    Normal maximum volume for red cell top-up transfusion is 1
                    unit. Transfusion rate: 5 mL/kg/hr (usual max rate 150
                    mL/hr).
                  </p>
                </li>
              </ul>
            </article>
          )}

          {currentView === 'FFP/CRYO' && (
            <article
              css={theme.components.rowSmall}
              className="theme"
              style={{ '--theme': theme.colours.yellow }}>
              <h1 className="mb-gap">
                Fresh frozen plasma and cryoprecipitate
              </h1>
              <h2>
                Correction of minor acquired abnormalities in non-bleeding
                patients (excluding DIC)
              </h2>

              <ul>
                <li>
                  <p>
                    FFP should not be administered to non-bleeding children with
                    minor prolongation of the PT/APTT (including prior to
                    surgery unless to critical sites).
                  </p>
                </li>
                <li>
                  <p>
                    Cryo should not be routinely administered to non-bleeding
                    children with decreased fibrinogen (including pre-op unless
                    fibrinogen &lt;1.0 g/L for surgery at risk of significant
                    bleeding or to critical sites).
                  </p>
                </li>
              </ul>
              <h2>Disseminated intravascular coagulation</h2>

              <ul>
                <li>
                  <p>
                    FFP may be beneficial in children with DIC who have a
                    significant coagulopathy (PT/APTT &gt;1.5 times midpoint of
                    normal range or fibrinogen &lt;1.0 g/L) associated with
                    clinically significant bleeding or prior to invasive
                    procedures.
                  </p>
                </li>
              </ul>
              <p>Typical transfusion volumes: FFP 15-20 mL/kg</p>
              <ul>
                <li>
                  <p>
                    Cryo may be given if the fibrinogen is &lt;1.0 g/L despite
                    FFP, or in conjunction with FFP for very low or rapidly
                    falling fibrinogen.
                  </p>
                </li>
              </ul>
              <p>Make sure that patients are vitamin K replete.</p>
              <p>
                Typical transfusion volumes: cryo 5-10 mL/kg; rate 10-20
                mL/kg/hr.
              </p>
            </article>
          )}

          {currentView === 'PLT' && (
            <article
              css={theme.components.rowSmall}
              className="theme"
              style={{ '--theme': theme.colours.purple }}>
              <h1 className="mb-gap">Platelets</h1>
              <p>
                For most stable children, transfuse prophylactic platelets when
                platelet count &lt;10 x 10{'\u2079'}/L (excluding ITP, TTP/HUS
                and HIT where platelets are only transfused for life-threatening
                bleeding).
              </p>
              <h2>Suggested transfusion thresholds for platelets</h2>

              <table className="table">
                <thead>
                  <tr>
                    <th>
                      Platelet count <br /> (x 10{'\u2079'}/L)
                    </th>
                    <th>Clinical situation to trigger platelet transfusion</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-center text-bold">&lt;10</td>
                    <td>
                      Irrespective of signs of haemorrhage (excluding ITP,
                      TTP/HUS, HIT)
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center text-bold">&lt;20</td>
                    <td>
                      Severe mucositis
                      <br />
                      Sepsis
                      <br />
                      Laboratory evidence of DIC in the absence of bleeding*
                      <br />
                      Anticoagulant therapy
                      <br />
                      Risk of bleeding due to a local tumour infiltration
                      <br />
                      Insertion of a non-tunnelled CVL
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center text-bold">&lt;40</td>
                    <td>Prior to lumbar puncture**</td>
                  </tr>
                  <tr>
                    <td className="text-center text-bold">&lt;50</td>
                    <td>
                      Moderate haemorrhage (e.g. gastrointestinal bleeding)
                      including bleeding in association with DIC
                      <br />
                      Surgery, unless minor (except at critical sites) –
                      including tunnelled CVL insertion
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center text-bold">&lt;75-100</td>
                    <td>
                      Major haemorrhage or significant postoperative bleeding
                      (e.g. post cardiac surgery)
                      <br />
                      Surgery at critical sites: CNS including eyes
                    </td>
                  </tr>
                </tbody>
              </table>

              <p>
                <small>
                  <em>
                    * Avoid routine coagulation screening without clinical
                    indication
                  </em>
                </small>
              </p>

              <p>
                <small>
                  <em>
                    ** Prior to lumbar puncture some clinicians will transfuse
                    platelets at higher or lower counts (e.g. 20-50 x 10
                    {'\u2079'}/L) depending on the clinical situation.
                  </em>
                </small>
              </p>

              <p>
                <small>
                  <em>
                    Typical transfusion volume 10-20 mL/kg (single pack for
                    children ≥15 kg, normal maximum 1 pack); rate 10-20
                    mL/kg/hr.
                  </em>
                </small>
              </p>
            </article>
          )}
        </div>
      </WithSidebar>
    </Layout>
  );
};

export default AdultsPage;
